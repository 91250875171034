import { CaretLeft, CaretRight } from '@mezzoforte/forge-icons';
import React from 'react';
import cx from 'classnames';
import { useTheme } from '../../theme';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import { VisuallyHidden } from './visually-hidden';
import { Text } from './text';
import { selectedTypographyFromTokens } from './text';
import { useBreakpointUnder, Responsive } from '../../hooks/breakpoints';
import { cartesianProps } from '../../utils/cartesian-props';
import { Box } from './box';

const defaultLang = {
  previousPage: 'Edellinen sivu',
  nextPage: 'Seuraava sivu',
  page: 'Sivu',
};

export const paginationVariants = ['default', 'small'] as const;
type Variant = (typeof paginationVariants)[number];

export interface PaginationProps
  extends Omit<
    ReactPaginateProps,
    'pageRangeDisplayed' | 'marginPagesDisplayed'
  > {
  /**
   * Variant
   */
  variant?: Variant;
  /**
   * Breakpoint what to use for switching from mobile to desktop styles
   */
  responsive?: Responsive;
  className?: string;
  defaultLang?: typeof defaultLang;
  /**
   * The total number of pages.
   */
  pageCount: number;
  /**
   * The method to call when a page is clicked. Exposes the current page object as an argument.
   */
  onPageChange?(selectedItem: { selected: number }): void;
  /**
   * The method to call when an active page is clicked. Exposes the active page object as an argument.
   */
  onPageActive?(selectedItem: { selected: number }): void;
  /**
   * The initial page selected.
   */
  initialPage?: number;
  /**
   * To override selected page with parent prop.
   */
  forcePage?: number;
  /**
   * Disable onPageChange callback with initial page. Default: false
   */
  disableInitialCallback?: boolean;
  /**
   * The event to listen onto before changing the selected page. Default is: "onClick".
   */
  eventListener?: string;
}

export const Pagination = React.forwardRef<
  React.Component<ReactPaginateProps>,
  PaginationProps
>(
  (
    {
      variant = 'default',
      responsive = 'md',
      className,
      defaultLang: customDefaultLang,
      ...props
    },
    ref
  ) => {
    const { forgeTokens } = useTheme();
    const isMobile = useBreakpointUnder(responsive);
    const widePageRangeDisplayed = variant === 'small' ? 2 : 5;
    const pageRangeDisplayed = isMobile ? 0 : widePageRangeDisplayed;
    const bodyBoldStyle = selectedTypographyFromTokens(forgeTokens, 'bodyBold');
    const language = { ...defaultLang, ...customDefaultLang };
    return (
      <Text
        as="div"
        display="inline-block"
        css={{
          '& .forge-pagination': {
            display: 'inline-block',
          },
          '& .forge-paginationitem': {
            display: 'inline-block',
            borderBottomWidth: forgeTokens.borderWidths.activeUnderline,
            borderColor: 'transparent',
            '& a': {
              padding: forgeTokens.space[2],
            },
            '&.forge-paginationitem--break': {
              position: 'relative',
              color: forgeTokens.colors.text,
              opacity: forgeTokens.opacities.disabled,
              cursor: 'default',
              pointerEvents: 'none',
              '&:after': {
                display: 'block',
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 3,
                width: '100%',
                height: '100%',
                cursor: 'default',
              },
            },
            '&.forge-paginationitem--prev, &.forge-paginationitem--next': {
              '& a': {
                padding: 0,
              },
            },
            '&.forge-paginationitem--active': {
              ...(bodyBoldStyle as Record<string, string | number>),
              borderBottom: forgeTokens.borders.activeUnderline,
            },
            '&.forge-paginationitem--disabled': {
              color: forgeTokens.colors.text,
              opacity: forgeTokens.opacities.disabled,
              cursor: 'default',
              pointerEvents: 'none',
            },
          },
        }}
      >
        <ReactPaginate
          ref={ref}
          previousLabel={
            <Box position="relative" top="2px">
              <CaretLeft aria-hidden="true" size={16} />
              <VisuallyHidden>{language.previousPage}</VisuallyHidden>
            </Box>
          }
          nextLabel={
            <Box position="relative" top="2px">
              <CaretRight aria-hidden="true" size={16} />
              <VisuallyHidden>{language.nextPage}</VisuallyHidden>
            </Box>
          }
          breakLabel="..."
          pageRangeDisplayed={pageRangeDisplayed}
          marginPagesDisplayed={1}
          {...props}
          containerClassName={cx(
            'forge-pagination',
            'forge-list--reset',
            className
          )}
          pageClassName="forge-paginationitem"
          breakClassName="forge-paginationitem forge-paginationitem--break"
          previousClassName="forge-paginationitem forge-paginationitem--prev"
          nextClassName="forge-paginationitem forge-paginationitem--next"
          activeClassName="forge-paginationitem--active"
          disabledClassName="forge-paginationitem--disabled"
          ariaLabelBuilder={(page) => `${language.page} ${page}`}
        />
      </Text>
    );
  }
);

/**
 * For testing
 */
interface ForTestingProps extends PaginationProps {
  children?: React.ReactNode;
}
const components = [
  cartesianProps<ForTestingProps>(
    {
      variant: [...paginationVariants],
      pageCount: [6],
      initialPage: [undefined, 2, 3, 4, 5],
      onPageChange: [() => null],
    },
    Pagination
  ),
  cartesianProps<ForTestingProps>(
    {
      variant: [...paginationVariants],
      pageCount: [114],
      initialPage: [undefined, 1, 113],
      onPageChange: [() => null],
    },
    Pagination
  ),
];

export const toTesting = <React.Fragment>{...components}</React.Fragment>;
